<template>
  <hero-section />
  <features-section />
<AvantagesSection />
</template>

<script>
import AvantagesSection from "@/components/AvantagesSection.vue";
import HeroSection from "@/components/HeroSection.vue";
import FeaturesSection from "@/components/FeaturesSection.vue";

export default {
  name: "AllComp",
  components: {FeaturesSection, HeroSection, AvantagesSection}
}
</script>

<style scoped>

</style>