<template>
  <div class="py-12">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="text-center mb-12">
        <h2 class="text-base font-semibold text-green-500 tracking-wide uppercase">Pricing</h2>
        <p class="mt-2 text-3xl leading-8 font-extrabold text-gray-900 sm:text-4xl">
          Choose your plan
        </p>
        <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
          Simple pricing plans to suit your needs.
        </p>
        <div class="mt-6">
          <label for="currency" class="block text-sm font-medium text-gray-700">Select Currency</label>
          <select v-model="selectedCurrency" @change="convertPrices" id="currency" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
            <option v-for="currency in Object.keys(conversionRates)" :key="currency" :value="currency">{{ currency }}</option>
          </select>
        </div>
      </div>

      <div class="mt-10 flex flex-wrap justify-center">
        <!-- Free Plan -->
        <div class="max-w-md w-full lg:flex-1 lg:max-w-xs bg-white border border-gray-200 rounded-lg shadow-sm p-6 m-4">
          <h3 class="text-lg leading-6 font-medium text-gray-900">Free</h3>
          <p class="mt-4 text-gray-500">Start using SpendGuard now as a limited version.</p>
          <div class="mt-6">
            <span class="text-4xl font-extrabold text-gray-900">{{ convertedPrices.free }} {{ selectedCurrency }}</span>
            <span class="text-base font-medium text-gray-500">/ month</span>
          </div>
          <a href="#" class="mt-8 w-full inline-flex justify-center items-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-green-600 bg-indigo-50 hover:bg-green-100">
            Get Started
          </a>
        </div>

        <!-- Lifetime Plan -->
        <div class="max-w-md w-full lg:flex-1 lg:max-w-xs bg-white border border-green-500 rounded-lg shadow-sm p-6 m-4">
          <h3 class="text-lg leading-6 font-medium text-gray-900">Lifetime</h3>
          <p class="mt-4 text-gray-500">Pay once and access all features forever.</p>
          <div class="mt-6">
            <span class="text-4xl font-extrabold text-gray-900">{{ convertedPrices.lifetime }} {{ selectedCurrency }}</span>
          </div>
          <a href="#" class="mt-8 w-full inline-flex justify-center items-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-500 hover:bg-green-700">
            Buy Now
          </a>
        </div>

        <!-- Custom Plan -->
        <div class="max-w-md w-full lg:flex-1 lg:max-w-xs bg-white border border-gray-200 rounded-lg shadow-sm p-6 m-4">
          <h3 class="text-lg leading-6 font-medium text-gray-900">Custom</h3>
          <p class="mt-4 text-gray-500">Tailor-made solutions to meet your business needs.</p>
          <div class="mt-6">
            <span class="text-4xl font-extrabold text-gray-900">Contact Us</span>
          </div>
          <a href="#" class="mt-8 w-full inline-flex justify-center items-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-green-600 bg-indigo-50 hover:bg-green-100">
            Learn More
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PricingSection",
  data() {
    return {
      selectedCurrency: "EUR",
      conversionRates: {
        EUR: 1,
      },
      prices: {
        free: 0,
        lifetime: 29.9,
      },
      convertedPrices: {
        free: 0,
        lifetime: 29.9,
      },
    };
  },
  methods: {
    async fetchConversionRates() {
      try {
        const response = await fetch('https://api.frankfurter.app/latest?base=EUR');
        const data = await response.json();
        this.conversionRates = data.rates;
        this.conversionRates['EUR'] = 1;
      } catch (error) {
        console.error('Error fetching or parsing JSON:', error);
      }
    },
    convertPrices() {
      const rate = this.conversionRates[this.selectedCurrency];
      this.convertedPrices.free = (this.prices.free * rate).toFixed(2);
      this.convertedPrices.lifetime = (this.prices.lifetime * rate).toFixed(2);
    }
  },
  async mounted() {
    await this.fetchConversionRates();
    this.convertPrices();
  }
};
</script>

<style scoped>

</style>
