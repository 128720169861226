<template>
  <section class="py-16">
    <div class="container mx-auto px-4">
      <div class="max-w-4xl mx-auto text-center">
        <h1 class="text-4xl font-bold text-gray-800 mb-6">About SpendGuard</h1>
        <p class="text-lg text-gray-600 mb-8">
          SpendGuard is your personal finance companion, designed to help you manage your budget and track your expenses effortlessly.
          Whether you're saving for a big purchase or just trying to stay on top of your day-to-day spending, SpendGuard provides all the tools you need
          to take control of your finances.
        </p>
      </div>

      <div class="flex flex-col md:flex-row justify-center gap-8">
        <div class="bg-white hover:z-10 hover:shadow-green-400 hover:border-4 duration-100 hover:scale-105 ease-out shadow-lg rounded-lg p-6 w-full md:w-1/3 transform transition-transform">
          <h2 class="text-2xl font-semibold text-gray-800 mb-4">Track Your Expenses</h2>
          <p class="text-gray-600">
            Log your daily transactions easily and get a clear view of where your money is going.
            SpendGuard categorizes your expenses automatically to make budgeting easier.
          </p>
        </div>

        <div class="bg-white hover:z-10 hover:shadow-green-400 hover:border-4 duration-100 hover:scale-105 ease-out shadow-lg rounded-lg p-6 w-full md:w-1/3 transform transition-transform">
          <h2 class="text-2xl font-semibold text-gray-800 mb-4">Set Budget Goals</h2>
          <p class="text-gray-600">
            Create and customize budget categories to fit your lifestyle.
            SpendGuard helps you monitor your spending in real-time, so you always know how close you are to reaching your financial goals.
          </p>
        </div>

        <div class="bg-white hover:z-10 hover:shadow-green-400 hover:border-4 duration-100 hover:scale-105 ease-out shadow-lg rounded-lg p-6 w-full md:w-1/3 transform transition-transform">
          <h2 class="text-2xl font-semibold text-gray-800 mb-4">Stay Alert</h2>
          <p class="text-gray-600">
            Get notified when you're nearing your budget limits or when significant transactions occur.
            SpendGuard ensures that you are always aware of your financial status, preventing overspending.
          </p>
        </div>
      </div>

      <div class="mt-12 max-w-3xl mx-auto text-center">
        <h2 class="text-3xl font-bold text-gray-800 mb-6">Why SpendGuard?</h2>
        <p class="text-lg text-gray-600 mb-8">
          SpendGuard isn't just another budgeting app. It’s built to help you take charge of your financial future,
          with user-friendly tools, detailed insights, and real-time updates, so you can make informed financial decisions every day.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutSection"
}
</script>

<style scoped>
</style>
