<template>
    <div class="bg-white py-24 sm:py-32">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
      <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Take Control of Your Finances</h2>
      <dl class="col-span-2 grid grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2">
        <div class="shadow-lg rounded-2xl p-6">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div class="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-green-600">
              <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
            </div>
            Automated Expense Tracking
          </dt>
          <dd class="mt-1 text-base leading-7 text-gray-600">Effortlessly track all your expenses with automatic synchronization of your bank transactions.</dd>
        </div>
        <div class="shadow-lg rounded-2xl p-6">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div class="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-green-600">
              <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14M5 12l7-7m0 14l-7-7" />
              </svg>
            </div>
            Customizable Budgeting
          </dt>
          <dd class="mt-1 text-base leading-7 text-gray-600">Create personalized budgets that fit your financial goals and track your progress in real-time.</dd>
        </div>
        <div class="shadow-lg rounded-2xl p-6">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div class="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-green-600">
              <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3 3v18h18V3H3zm9 11.25h-3v-6h3v6zm6 0h-3v-6h3v6z" />
              </svg>
            </div>
            Visual Financial Insights
          </dt>
          <dd class="mt-1 text-base leading-7 text-gray-600">Visualize your financial data with interactive charts and reports that help you understand your spending patterns.</dd>
        </div>
        <div class="shadow-lg rounded-2xl p-6">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div class="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-green-600">
              <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 12h9.75m-9.75 0H3M21 21H3V3h18v18z" />
              </svg>
            </div>
            Goal Setting & Alerts
          </dt>
          <dd class="mt-1 text-base leading-7 text-gray-600">Set financial goals and receive alerts to help you stay on track and achieve your savings objectives.</dd>
        </div>
      </dl>
    </div>
  </div>
</div>


</template>
<script>
export default {
    name: 'FeaturesSection'
}
</script>
