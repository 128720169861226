<!-- src/App.vue -->
<template>
  <div id="app">
    <NavbarSection />
    <router-view />
    <FooterSection />
  </div>
</template>

<script>
import NavbarSection from "@/components/NavbarSection.vue";
import FooterSection from "@/components/FooterSection.vue";
export default {
  components: {
    NavbarSection,
    FooterSection,
  },
};
</script>
