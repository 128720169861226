
<template>
  
  <div class="bg-white">
  <div class="relative isolate pt-14">
    <svg class="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]" aria-hidden="true">
      <defs>
        <pattern id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
          <path d="M100 200V.5M.5 .5H200" fill="none" />
        </pattern>
      </defs>
      <svg x="50%" y="-1" class="overflow-visible fill-gray-50">
        <path d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z" stroke-width="0" />
      </svg>
      <rect width="100%" height="100%" stroke-width="0" fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
    </svg>
    <div class="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40">
      <div class="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
        <h1 class="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">SpendGuard</h1>
        <h4 class="mt-10 max-w-lg text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">Take Control of Your Finances with SpendGuard</h4>
        <p class="mt-6 text-lg leading-8 text-gray-600">Track, plan, and save effortlessly with our intuitive budgeting tool.</p>
        <div class="mt-10 flex items-center gap-x-6">
          <a href="/price" class="rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Try It Today</a>
          <a href="/about" class="text-sm font-semibold leading-6 text-gray-900">Learn more <span aria-hidden="true">→</span></a>
        </div>
      </div>
      <img id="logoHero" src="img/logo.png">
    </div>
  </div>
</div>

</template>

<script>
export default {
  name: 'HeroSection'
};
</script>
<style scoped>
@media screen and (max-width: 1036px){
  #logoHero {
    display: none;
  }

}
</style>
