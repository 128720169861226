import { createApp } from 'vue'
import App from './App.vue'
import * as VueRouter from 'vue-router'
import FeaturesSection from "@/components/FeaturesSection.vue";
import allComp from "@/components/AllComp.vue";
import PricingSection from "@/components/PricingSection.vue";
import ContactSection from "@/components/ContactSection.vue";
import NotFound from "@/components/NotFound.vue";
import AboutSection from "@/components/AboutSection.vue";




const router = VueRouter.createRouter({
    history: VueRouter.createWebHistory(),

    routes: [
        {
            path: '/',
            name: 'Home',
            component: allComp
        },
        {
            path: '/features',
            name: 'features',
            component: FeaturesSection
        },
        {
            path: '/price',
            name: 'pricing',
            component: PricingSection
        },
        {
            path: '/contact',
            name: 'contact',
            component: ContactSection
        },
        {
            path: '/:pathMatch(.*)',
            name: 'Notfound',
            component: NotFound
        },
        {
            path: '/about',
            name: 'About',
            component: AboutSection
        }

    ]
})

const app = createApp(App)
app.use(router).mount('#app')
app.mount('app')

