<template>
  <div className="min-h-screen flex flex-col items-center justify-center px-4">
    <div className="text-center">
      <h1 className="text-green-600 text-sm font-semibold mb-2">404</h1>
      <h2 className="text-4xl font-bold text-gray-900 mb-4">Page not found</h2>
      <p className="text-gray-600 mb-6">
        Sorry, we couldn't find the page you're looking for.
      </p>
      <div className="flex justify-center space-x-4">
        <router-link
            to="/"
            className="px-6 py-3 bg-green-600 text-white font-semibold rounded-md hover:bg-green-700 transition duration-300"
        >
          Go back home
        </router-link>
        <router-link
            to="/contact"
            className="flex items-center text-green-600 font-medium hover:text-green-700 transition duration-300"
        >
          Contact support →
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>
/* Style additionnel au besoin */
</style>
