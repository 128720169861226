<template>
  <div class="bg-white py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="text-center">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Why Choose SpendGuard?</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600">Discover why SpendGuard is the ideal solution for managing your personal finances with efficiency and security.</p>
      </div>
      <div class="mt-16 max-w-lg sm:max-w-none">
        <div class="grid grid-cols-1 gap-y-16 md:grid-cols-2 md:gap-x-12 md:gap-y-16">
          <div class="relative flex flex-col gap-6 rounded-2xl p-6 bg-white shadow-lg">
            <div class="flex h-12 w-12 items-center justify-center rounded-xl bg-blue-500 text-white">
              <svg class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M5 12l5 5L20 7" />
              </svg>
            </div>
            <div class="text-base font-semibold leading-7 text-gray-900">
              <p class="text-lg font-medium text-gray-900">Ease of Use</p>
              <p class="mt-2 text-base leading-7 text-gray-600">With an intuitive interface and quick setup process, SpendGuard makes managing your finances as simple as possible. Spend less time struggling with complicated tools and more time reaching your financial goals.</p>
            </div>
          </div>
          <div class="relative flex flex-col gap-6 rounded-2xl p-6 bg-white shadow-lg">
            <div class="flex h-12 w-12 items-center justify-center rounded-xl bg-green-500 text-white">
              <svg class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 12v9m0-9l-6 6m6-6l6 6" />
              </svg>
            </div>
            <div class="text-base font-semibold leading-7 text-gray-900">
              <p class="text-lg font-medium text-gray-900">Data Security</p>
              <p class="mt-2 text-base leading-7 text-gray-600">We take the security of your information very seriously. SpendGuard uses advanced security protocols to ensure your financial data is protected and confidential.</p>
            </div>
          </div>
          <div class="relative flex flex-col gap-6 rounded-2xl p-6 bg-white shadow-lg">
            <div class="flex h-12 w-12 items-center justify-center rounded-xl bg-red-500 text-white">
              <svg class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
              </svg>
            </div>
            <div class="text-base font-semibold leading-7 text-gray-900">
              <p class="text-lg font-medium text-gray-900">Effective Budget Tracking</p>
              <p class="mt-2 text-base leading-7 text-gray-600">Get a clear overview of your finances with precise tracking tools and detailed reports. SpendGuard helps you understand where your money is going and optimize your spending to better achieve your financial goals.</p>
            </div>
          </div>
          <div class="relative flex flex-col gap-6 rounded-2xl p-6 bg-white shadow-lg">
            <div class="flex h-12 w-12 items-center justify-center rounded-xl bg-purple-500 text-white">
              <svg class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10 6h4m-2-2v4M6 12h12m-6 6h6m-6 0H6" />
              </svg>
            </div>
            <div class="text-base font-semibold leading-7 text-gray-900">
              <p class="text-lg font-medium text-gray-900">Multi-Platform Accessibility</p>
              <p class="mt-2 text-base leading-7 text-gray-600">Access your finances from any device, whether it's your computer, smartphone, or tablet. SpendGuard is designed to be available and functional wherever you need it.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AvantagesSection'
}
</script>
